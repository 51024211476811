$(document).ready(function () {
  var nav = $('.home-hero .slide').length > 1 ? true : false;
  var options = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    autoplay: true,
    autoplaySpeed: 10000,
    speed: 500,
    adaptiveHeight: true,
    accessibility: false,
    pauseOnDotsHover: true,
    customPaging: function (slider, i) {
      var slideNumber = (i + 1),
          totalSlides = slider.slideCount;
      return '<button type="button"><span class="sr-only">Go to slide ' + slideNumber + ' of ' + totalSlides + '</span></button>';
    }
    //dots: true,
  };
  if (nav) { options.dots = true; }

  $('.home-hero').on('init', function (event, slick) {
    if ($('#' + slick.$slides[0].id).has('.caption').length) {
      $('#' + slick.$slides[0].id).find('.caption').addClass('visible');
    };

    $('.slick-dots').find('.slick-active').attr('aria-current', 'true');
    $('.slick-list').attr('id', 'ecu-feature-carousel');
    $('.slick-list').attr('aria-live', 'off');

    $('.slick-slide').each( function() {
          $(this).attr('role', 'group');
    });
  });

  $('.home-hero').slick(options);

  if (nav) {
    $('.slick-dots').append("<li><button type='button' class='playpause pause' aria-label='pause slideshow' aria-pressed='false' aria-controls='ecu-feature-carousel'></button></li>");
  }

  //Play/Pause Carousel
  $('.playpause').on('click', function () {
    if ($(this).hasClass('play')) {
      $(this).attr('aria-pressed', 'false');
      $(this).attr('aria-label', 'pause slideshow');
      $(this).removeClass('play').addClass('pause');
      $('#feature-carousel').slick('slickPlay');
      $('.slick-list').attr('aria-live', 'off');
    } else {
      $(this).attr('aria-pressed', 'true');
      $(this).attr('aria-label', 'play slideshow');
      $(this).removeClass('pause').addClass('play');
      $('#feature-carousel').slick('slickPause');
      $('.slick-list').attr('aria-live', 'polite');
    }
  });

  $('.slick-dots li button:not(.playpause)').on('click', function() {
    $('#feature-carousel').slick('slickPause');
    $('.slick-list').attr('aria-live', 'polite');
    $('.playpause').attr('aria-pressed', 'true');
    $('.playpause').attr('aria-label', 'play slideshow');
    $('.playpause').removeClass('pause').addClass('play');
  });

  $('.home-hero').on('afterChange', function (event, slick, currentSlide, nextSlide) {
    if ($('#' + slick.$slides[currentSlide].id).has('.caption').length) {
      $('#' + slick.$slides[currentSlide].id).find('.caption').addClass('visible')
    }

    $('.slick-dots .slick-active').attr('aria-current', 'true');  
  });

  $('.home-hero').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
    $('.slick-dots li').removeAttr('aria-current');
      
    if ($('#' + slick.$slides[currentSlide].id).has('.caption').length) {
      $('#' + slick.$slides[currentSlide].id).find('.caption').removeClass('visible')
    }
  });

});
